import { useEffect, useState } from 'react'

export default function useMapCardSizes() {
  const [sizes, setSizes] = useState({ list: 0, single: 0 })
  const resize = () => {
    if (window.innerWidth > 1535) {
      if (sizes.list !== 688) {
        setSizes({
          list: 688,
          single: 500,
        })
      }
    } else if (window.innerWidth > 1023) {
      if (sizes.list !== 352) {
        setSizes({
          list: 352,
          single: 400,
        })
      }
    } else if (sizes.list !== 0) {
      setSizes({
        list: 0,
        single: 0,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    resize()
    return () => window.removeEventListener('resize', resize)
  }, [])

  return sizes
}
